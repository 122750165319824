// Sarah's customizations

// Add Fira Sans font (local)
@font-face {
  font-family: 'fira_sansmedium';
  src: url('/assets/fonts/firasans-medium-webfont.woff2') format('woff2'),
  url('/assets/fonts/firasans-medium-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

// Turn headers teal
h1,
h2,
h3,
h4,
h5,
h6 {
  color: $theme-teal-hex;
}

// Turn title teal and decreas spacing between nav items
nav a.site-title {
  color: $theme-teal-hex;
}
nav li.masthead__menu-item > a {
  margin: 0px 9px;
}

// Home/Index feature_block table formatting

.home_feature_table {
  display: table;
  width: 100%;
}
.home_feature_table_title {
  display: table-caption;
  text-align: center;
  font-weight: bold;
  font-size: larger;
}
.home_feature_table_heading {
  display: table-row;
  font-weight: bold;
  text-align: center;
}
.home_feature_table_row {
  display: table-row;
  vertical-align: top;
}
.home_feature_table_cell {
  display: table-cell;
  border: none;
  padding-left: 5px;
  padding-right: 5px;
  vertical-align: top;
}
.home_feature_table_cell_third {
  display: table-cell;
  border: none;
  padding-left: 5px;
  padding-right: 20px;
  width: 33%;
}
.home_feature_table_cell_third h2 {
  text-align: center;
}
.home_feature_table_cell_third p {
  text-align: center;
}
.home_feature_table_cell_image {
  display: table-cell;
  border: none;
  padding-left: 5px;
  padding-right: 5px;
  width: 125px;
  text-align: center;
  vertical-align: middle;
}

// Maps page width/height of the map
#mapid {
  width: 1080px;
  height: 810px;
}

// Blog category/tag/etc. layouts
.blog_index_table_row {
  display: table-row;
  vertical-align: top;
}

// Blog category/tag/etc. laytouts
.blog_index_table_row h1,
.blog_index_table_row h2,
.blog_index_table_row h3,
.blog_index_table_row h4,
.blog_index_table_row h5,
.blog_index_table_row h6,
{
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
}

.blog_index_table_cell_image {
  display: table-cell;
  border: 0px;
  padding-left: 5px;
  padding-right: 5px;
  vertical-align: top;
  width: 20%;
}
.blog_index_table_cell_blurb {
  display: table-cell;
  border: 0px;
  padding-left: 5px;
  padding-right: 5px;
  vertical-align: top;
  width: auto;
}
.blog-roll-image {
  height: auto;
  width: 100%
}